import Description from "./components/description/description";
import Header from "./components/header/header";
import Links from "./components/links/links";

import classes from "./App.module.css";
import Photo from "./components/photo/photo";

function App() {
  return (
    <div className={classes.app__container}>
      <div className={classes.app__left_side}>
        <Header />
        <Description />
      </div>
      <div className={classes.app__buttons_photo_wrapper}>
        <div className={classes.app__center_side}>
          <Links />
        </div>
        <div className={classes.app__right_side}>
          <Photo />
        </div>
      </div>
    </div>
  );
}

export default App;
