import classes from "./description.module.css";

const Description = () => {
  return (
    <section>
      <div className={classes.description__wrapper}>
        <p>Poczuj się jak u siebie!</p>
        <p>
          W biurze projektowym „Lekka Forma” skupiamy się na tworzeniu wnętrz,
          które są nie tylko stylowe, ale przede wszystkim komfortowe.
        </p>
        <p>
          Naszą misją jest projektowanie pomieszczeń, które zachwycają od
          pierwszego wejrzenia i zachęcają do spędzania w nich czasu. Nie ma dla
          nas rzeczy niemożliwych, dlatego zrobimy wszystko, aby odzwierciedlały
          one zarówno nowoczesne rozwiązania architektoniczne, jak i Twoje
          potrzeby oraz styl życia. Wierzymy, że dzięki sprawnemu planowaniu i
          naszym płynącym z doświadczenia wskazówkom, każdy projekt – także
          Twój! – można zrealizować bez zbędnego stresu.
        </p>
      </div>
      <div className={classes.description__contact_details}>
        <p>SKONTAKTUJ SIĘ Z NAMI:</p>
        <a href="mailto:lekkaforma.pracownia@gmail.com">lekkaforma.pracownia@gmail.com</a>
        <p>tel. Anna Habecka - 539 330 991</p>
        <p>tel. Hanna Perdeusz - 660 350 791</p>
      </div>
    </section>
  );
};

export default Description;
