import IMG_URL from "../../assets/logo.png";
import classes from "./header.module.css";

const Header = () => {
  return (
    <div className={classes.header__wrapper}>
      <div>
        <img src={IMG_URL} alt="LF Logo" className={classes.logo} />
      </div>
      <div>
        <h1 className={classes.title}>LEKKA FORMA</h1>
        <h2 className={classes.subtitle}>PRACOWNIA PROJEKTOWA</h2>
      </div>
    </div>
  );
};

export default Header;
